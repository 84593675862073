import request from "@/utils/request.js"
//修改生鲜商品

export const freshChangechainGood=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/freshChange',
    data: data
  });
  } 

//修改非生鲜商品

export const changechainGoodsupermarket=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/change',
    data: data
  });
  } 

//获取商品列表
export const getGoodsList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/good/list',
      params,
    });
    } 
  //获取所有的商品分类
export const getAllShopCategory_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/getAllShopCategory',
    params,
  });
  } 
  //获取日志列表
export const getLogRecord_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/getLogRecord',
    params,
  });
  } 
    //获取账号列表
  export const getChainUserAccountList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/account/getChainUserAccountList',
    params,
  });
  } 
//新增账号列表
export const createChainUserAccount_api=data=>{
  return  request({
    method: 'post',
    url: 'api/v2/account/createChainUserAccount',
    data: data
  });
  } 
  //编辑账号列表
  export const editChainUserAccount_api=data=>{
    return  request({
      method: 'post',
      url: 'api/v2/account/editChainUserAccount',
      data: data
    });
    } 
     //获取连锁所有区域
  export const getChainRegion_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v2/shop/getChainRegion',
      params,
    });
    } 
//修改商品信息
export const changeGoodsInfo_api=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/good/update',
    data: data
  });
  } 
//超市修改商品信息
export const changeGoodsInfo_api1=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/good/update',
    data: data
  });
  } 
  //商品统计
  export const GoodsStatistics_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/good/statistics/list',
      params,
    });
    } 
     //门店商品统计
  export const GoodsStatistics_api1=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/store/good/statistics/list',
      params,
    });
    } 
  //删除商品

  export const chainGoodDelete_api=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/chainGood/delete',
      data: data
    });
    } 
    //删除商品(超市)
    export const chainGoodDelete_api1=data=>{
      return  request({
        method: 'post',
        url: 'api/v1/supermarket/chainGood/freshDelete',
        data: data
      });
      } 
  //删除商品(连锁)

  export const chainGooddelete=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/supermarket/chainGood/delete',
      data: data
    });
    } 
  
    
    //连锁超市商品列表
    export const supermarketGoodlist=params=>{
      return  request({
        method: 'get',
        url: 'api/v1/supermarketGood/list',
        params
      });
      } 
  //
  
  export const goodSerieslist=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/goodSeries/list',
      params
    });
    } 